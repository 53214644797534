import '../../app/vendors/css/forms/select/select2.min.css';
import select2 from 'select2';
import Modal from "bootstrap/js/src/modal";
import repeater from 'jquery.repeater';
import {deleteDialog, swalError, swalSuccess, swalUnknown, toggleLoading} from "../functions";
import Popover from "bootstrap/js/src/popover";

function onNextStepAuth_b019761f() {
    let helper = $('#helper');
    let currentSelectMethod = document.querySelector('input[name=twoFactorAuthRadio]:checked').value;

    if (currentSelectMethod === 'apps-auth') {
        window.twoFactorAuthModal.hide();
        window.authAppsModal.show();
    } else {
        $.ajax({
            url: helper.data('send-email'),
            type: "post",
            async: true,
            cache: false,
            contentType: false,
            processData: false
        });

        window.twoFactorAuthModal.hide();
        window.authEmailModal.show();
    }
}

function onSaveButtonCUP_b019761f(event) {
    let pageResetPasswordForm = $('#changePasswordForm');

    pageResetPasswordForm.validate({
        rules: {
            'old-password': {
                required: true
            },
            'new-password1': {
                required: true
            },
            'new-password2': {
                required: true,
                equalTo: '#new-password1'
            }
        }
    });

    if (pageResetPasswordForm[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    pageResetPasswordForm.addClass('was-validated');
}

function onSaveButtonUPD_b019761f(event) {
    let form = $("#personal-details-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onSaveButtonEAF_b019761f(event) {
    let form = $("#email-auth-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onSaveButtonGAF_b019761f(event) {
    let form = $("#google-auth-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onSaveButtonCUN_b019761f(event) {
    let form = $("#notificationsForm");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onSaveButtonCUS_b019761f(event) {
    let form = $("#shortcutsForm");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onChangeNotificationsForm_b019761f(event) {
    toggleLoading('#saveButtonCUN', '#loadingButtonCUN');
    event.preventDefault();
    let form = $(this);
    let url = $('#helper').data('notifications');
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: "post",
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            window.notifications = response.notifications;
            form.removeClass("was-validated");
            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        $('#receiveMissedNotifications').prop("disabled", true);
        toggleLoading('#saveButtonCUN', '#loadingButtonCUN');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onChangeShortcutsForm_b019761f(event) {
    toggleLoading('#saveButtonCUS', '#loadingButtonCUS');
    event.preventDefault();
    let form = $(this);
    let url = $('#helper').data('shortcuts');
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: "post",
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            $('ul.bookmark-icons').html(response.shortcuts);
            feather.replace({width: 14, height: 14});

            let popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));

            popoverTriggerList.map(function (popoverTriggerEl) {
                return new Popover(popoverTriggerEl, {});
            });

            form.removeClass("was-validated");
            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButtonCUS', '#loadingButtonCUS');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onChangePasswordForm_b019761f(event) {
    toggleLoading('#saveButtonCUP', '#loadingButtonCUP');
    event.preventDefault();
    let form = $(this);
    let url = $('#helper').data('password');
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: "post",
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            form.removeClass("was-validated");
            $("#changePasswordForm").trigger('reset');
            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButtonCUP', '#loadingButtonCUP');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onActivateEmailAuth_b019761f(event) {
    toggleLoading('#saveButtonEAF', '#loadingButtonEAF');
    event.preventDefault();
    let form = $(this);
    let helper = $('#helper');
    let url = helper.data('activate-2fa');
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: "post",
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            form.removeClass("was-validated");

            $('#disable-two-fa').removeClass('d-none');
            $('#two-fa-status').removeClass('text-danger').addClass('text-success').text(helper.data('enabled'));

            window.authEmailModal.hide();
            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButtonEAF', '#loadingButtonEAF');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onActivateGoogleAuth_b019761f(event) {
    toggleLoading('#saveButtonGAF', '#loadingButtonGAF');
    event.preventDefault();
    let form = $(this);
    let helper = $('#helper');
    let url = helper.data('activate-2fa');
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: "post",
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            form.removeClass("was-validated");

            $('#disable-two-fa').removeClass('d-none');
            $('#two-fa-status').removeClass('text-danger').addClass('text-success').text(helper.data('enabled'));

            window.authAppsModal.hide();
            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButtonGAF', '#loadingButtonGAF');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onUpdatePersonalDataForm_b019761f(event) {
    toggleLoading('#saveButtonUPD', '#loadingButtonUPD');
    event.preventDefault();
    let form = $(this);
    let url = $('#helper').data('personal');
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: "post",
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            form.removeClass("was-validated");
            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButtonUPD', '#loadingButtonUPD');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onAccountActivation_b019761f() {
    let deactivateButton = $('.deactivate-account');

    if ($('#accountActivation').prop('checked')) {
        deactivateButton.prop('disabled', false);
    } else {
        deactivateButton.prop('disabled', 'disabled');
    }
}

function onDeactivateAccount_b019761f() {
    let helper = $('#helper');

    deleteDialog(null, helper.data('delete-confirm'), null, helper.data('delete-button'), helper.data('delete-url'), (data) => {
        window.location.href = data.url;
    });
}

function iconFormat_b019761f(icon) {
    if (!icon.id) {
        return icon.text;
    }

    return '<span class="ms-' + $(icon.element).data('depth') + '">' + feather.icons[$(icon.element).data('icon')].toSvg() + icon.text + '</span>';
}

export function initProfilePage() {
    window.select2 = select2;
    window.repeater = repeater;

    window.twoFactorAuthModal = new Modal(document.getElementById('twoFactorAuthModal'));
    window.authAppsModal = new Modal(document.getElementById('twoFactorAuthAppsModal'));
    window.authEmailModal = new Modal(document.getElementById('twoFactorAuthEmailModal'));

    let helper = $('#helper');

    if ($('html').attr('lang') === 'hu') {
        require('select2/dist/js/i18n/hu.js');
    }

    window.showAuthModal = function () {
        window.twoFactorAuthModal.show();
    };

    window.disableTwoFa = function () {
        deleteDialog(null, helper.data('2fa-confirm'), null, helper.data('yes'), helper.data('remove-2fa'), () => {
            $('#disable-two-fa').addClass('d-none');
            $('#two-fa-status').addClass('text-danger').removeClass('text-success').text(helper.data('disabled'));
        });
    };

    $('.invoice-repeater').repeater({
        isFirstItemUndeletable: true,
        show: function () {
            $('.repeater-wrapper:not(:first)').removeClass('d-none');
            $(this).slideDown();

            $('.shortcut-select:not(.select2-hidden-accessible)').select2({
                dropdownAutoWidth: true,
                width: '100%',
                minimumResultsForSearch: 1,
                templateResult: iconFormat_b019761f,
                templateSelection: iconFormat_b019761f,
                escapeMarkup: function (es) {
                    return es;
                }
            });

            if (feather) {
                feather.replace({width: 14, height: 14});
            }
        },
        hide: function (deleteElement) {
            $(this).slideUp(deleteElement, function () {
                $(this).remove();
            });
        }
    });

    $('.shortcut-select:not(.select2-hidden-accessible)').select2({
        dropdownAutoWidth: true,
        width: '100%',
        minimumResultsForSearch: 1,
        templateResult: iconFormat_b019761f,
        templateSelection: iconFormat_b019761f,
        escapeMarkup: function (es) {
            return es;
        }
    });

    $('#nextStepAuth').on('click', onNextStepAuth_b019761f);
    $("#saveButtonCUP").on("click", onSaveButtonCUP_b019761f);
    $("#saveButtonUPD").on("click", onSaveButtonUPD_b019761f);
    $("#saveButtonEAF").on("click", onSaveButtonEAF_b019761f);
    $("#saveButtonGAF").on("click", onSaveButtonGAF_b019761f);
    $("#saveButtonCUN").on("click", onSaveButtonCUN_b019761f);
    $("#saveButtonCUS").on("click", onSaveButtonCUS_b019761f);
    $('#accountActivation').on('click', onAccountActivation_b019761f);
    $('.deactivate-account').on('click', onDeactivateAccount_b019761f);
    $('#email-auth-form').on('submit', onActivateEmailAuth_b019761f);
    $('#google-auth-form').on('submit', onActivateGoogleAuth_b019761f);
    $("#changePasswordForm").on('submit', onChangePasswordForm_b019761f);
    $("#personal-details-form").on('submit', onUpdatePersonalDataForm_b019761f);
    $("#notificationsForm").on('submit', onChangeNotificationsForm_b019761f);
    $("#shortcutsForm").on('submit', onChangeShortcutsForm_b019761f);
}

export function unloadProfilePage() {
    delete window.select2;
    delete window.repeater;
    delete window.showAuthModal;

    $('.shortcut-select').select2('destroy');
    $('#nextStepAuth').off('click', onNextStepAuth_b019761f);
    $("#saveButtonCUP").off("click", onSaveButtonCUP_b019761f);
    $("#saveButtonUPD").off("click", onSaveButtonUPD_b019761f);
    $("#saveButtonEAF").off("click", onSaveButtonEAF_b019761f);
    $("#saveButtonGAF").off("click", onSaveButtonGAF_b019761f);
    $("#saveButtonCUN").off("click", onSaveButtonCUN_b019761f);
    $("#saveButtonCUS").off("click", onSaveButtonCUS_b019761f);
    $('#accountActivation').off('click', onAccountActivation_b019761f);
    $('.deactivate-account').off('click', onDeactivateAccount_b019761f);
    $('#email-auth-form').off('submit', onActivateEmailAuth_b019761f);
    $('#google-auth-form').off('submit', onActivateGoogleAuth_b019761f);
    $("#changePasswordForm").off('submit', onChangePasswordForm_b019761f);
    $("#personal-details-form").off('submit', onUpdatePersonalDataForm_b019761f);
    $("#notificationsForm").off('submit', onChangeNotificationsForm_b019761f);
    $("#shortcutsForm").off('submit', onChangeShortcutsForm_b019761f);
}